<template>
  <v-container fill-height fluid class="bg">
    <v-container fill-height fluid>
      <v-row justify="center">
        <v-col v-if="!canDownload" cols="12" class="d-flex justify-center">
          <brand></brand>
        </v-col>
        <v-col v-if="!canDownload" cols="12" class="d-flex justify-center">
          <div class="mudadores-title big">Certificado</div>
        </v-col>
        <v-col v-if="isLoading" cols="12" class="d-flex justify-center mt-16">
          <div class="d-flex flex-column align-center">
            <v-progress-circular
              color="yellow"
              indeterminate
            ></v-progress-circular>
            <div class="mt-2">Carregando</div>
          </div>
        </v-col>
        <v-col v-else-if="canDownload" cols="12" class="d-flex justify-center">
          <certificate-download></certificate-download>
        </v-col>
        <v-col v-else cols="12" class="d-flex justify-center">
          <v-alert type="info" color="primary" text>
            Complete a trilha para receber o certificado!
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <Navbar />
  </v-container>
</template>

<script>
import CertificateDownload from "../components/global/CertificateDownload.vue";
import Brand from "../components/global/Brand.vue";
import { mapGetters, mapActions } from "vuex";
import Navbar from "../components/Navbar";
export default {
  name: "Certificates",
  components: { Navbar, CertificateDownload, Brand },
  data: () => ({
    isLoading: true,
    canDownload: false,
  }),
  mounted() {
    this.onEnter();
  },
  computed: {
    ...mapGetters(["trackStatus"]),
  },
  methods: {
    ...mapActions(["fetchTrack"]),
    async onEnter() {
      try {
        this.isLoading = true;
        await this.fetchTrack("aprendizagem");
        if (this.trackStatus == "closed") this.canDownload = true;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>